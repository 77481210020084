<template>
  <div class="container">
    <scroller
      :on-refresh="refresh"
      refreshText="Pull to reload"
      releaseText="Pull to reload"
    >
      <nav>
        <span @click="display('Sport')">All sport</span>
        <button id="menubutton" @click="displayMenu()">☰</button>
        <ul id="menu">
          <li
            v-for="(genre, index) in genres"
            :key="index"
            @click="display(genre)"
          >
            {{ genre }}
          </li>
        </ul>
      </nav>
      <div class="content">
        <div class="live">
          <h2>Live</h2>
          <Programme
            class="program"
            v-for="item in liveData.filter(x => {
              return x.genres.some(genre => genre.name == show)
            })"
            :key="item.uid"
            :program="item"
            :channel="getChannel(item.externalIds[3].id)"
          />
        </div>
        <!-- </div>
      <div class="content">-->
        <div class="coming">
          <h2>Kommande</h2>
          <Programme
            class="program"
            v-for="item in comingData.filter(x => {
              return x.genres.some(genre => genre.name == show)
            })"
            :key="item.uid"
            :program="item"
            :channel="getChannel(item.externalIds[3].id)"
          />
        </div>
      </div>
    </scroller>
  </div>
</template>

<script>
import Vue from 'vue'
import Scroller from 'vue-scroller-loading'
import Programme from './components/Programme'

Vue.use(Scroller)

export default Vue.extend({
  components: { Programme },
  data() {
    return {
      channels: [],
      // epgData: [],
      liveData: [],
      comingData: [],
      show: 'Sport'
      // genres: []
    }
  },
  computed: {
    genres: function() {
      console.log(this.liveData)
      let genres = []
      this.liveData.forEach(game => {
        console.log('Game:', game)
        game.genres.forEach(genre => {
          genres.push(genre.name)
        })
      })
      this.comingData.forEach(game => {
        game.genres.forEach(genre => {
          genres.push(genre.name)
        })
      })
      console.log('Genres:', genres)
      return [...new Set(genres.filter(e => e != 'Sport'))]
    }
  },
  methods: {
    refresh: function(done) {
      var self = this
      setTimeout(function() {
        self.getEPG('all')
        self.getEPG('live')
        done()
      }, 1500)
    },
    // getGenres: function() {
    //   console.log(this.liveData);
    //   let genres = [];
    //   this.liveData.forEach(game => {
    //     console.log("Game:", game);
    //     game.genres.forEach(genre => {
    //       genres.push(genre.name);
    //     });
    //   });
    //   this.comingData.forEach(game => {
    //     game.genres.forEach(genre => {
    //       genres.push(genre.name);
    //     });
    //   });
    //   console.log("Genres:", genres);
    //   this.genres = [...new Set(genres)];
    // },
    displayMenu: function() {
      let button = document.getElementById('menubutton')
      let ul = document.getElementById('menu')
      if (button.innerText == '☰') {
        button.innerText = 'X'
        ul.style.display = 'block'
      } else {
        button.innerText = '☰'
        ul.style.display = 'none'
      }
    },
    getChannel: function(id) {
      let channel = this.channels.filter(chan => chan.id == id)
      return channel[0]
    },
    display: function(name) {
      // this.getEPG(name);
      this.show = name
    },
    getEPG: function(choosen) {
      let url = ''
      switch (choosen) {
        case 'ball':
          url =
            'https://cf-eu-west-1-cdproduction-se.cloud.xstream.dk/zuul/catalogue/canaldigitaldth/catalogue/filters/epg-football-later-today?page=1&size=30'
          break
        case 'all':
          url =
            'https://cf-eu-west-1-cdproduction-se.cloud.xstream.dk/zuul/catalogue/canaldigitaldth/catalogue/filters/epg-all-sports-later-today?page=1&size=100'
          break
        default:
          url =
            'https://cf-eu-west-1-cdproduction-se.cloud.xstream.dk/zuul/catalogue/canaldigitaldth/catalogue/filters/epg-all-sports-on-now?page=1&size=100'
      }
      fetch(url, {
        headers: {
          'X-User-Platform': 'web',
          'X-User-Device': 'web',
          'X-User-Domain': 'se',
          'Accept-Language': 'sv',
          Accept: 'application/json',
          'X-BU': 'dth'
        }
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          // this.epgData = data.content;
          if (choosen == 'live') {
            this.liveData = data.content
          }
          if (choosen == 'all') {
            this.comingData = data.content
          }
        })
      // this.getGenres();
    },
    getChannels: function() {
      fetch(
        'https://api.canaldigital.no/api/v1/media-list/5470?limit=80&offset=0',
        {
          headers: {
            'X-User-Platform': 'web',
            'X-User-Device': 'web',
            'X-User-Domain': 'se',
            'Accept-Language': 'sv',
            Accept: 'application/json',
            'X-BU': 'dth'
          }
        }
      )
        .then(response => {
          return response.json()
        })
        .then(result => {
          this.channels = result.data
        })
    }
  },
  created() {
    this.getChannels()
    this.getEPG('all')
    this.getEPG('live')
  },
  mounted() {
    console.log(this.genres)
    // this.getGenres();
  }
})
</script>

<style>
body {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

img {
  background-color: #e1e1e1;
}

#menubutton {
  display: none;
}

nav {
  background-color: #d6efff;
  width: 100vw;
  background-color: #d6efff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
ul {
  display: flex;
}

li {
  list-style-type: none;
  margin: 0 0.2vw;
}
li:hover {
  background-color: #a4dcff;
}

.selector {
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: white;
  cursor: pointer;
  background-color: green;
  color: white;
}
.program {
  padding-top: 5px;
}

@media (prefers-color-scheme: dark) {
  html {
    filter: invert(1) hue-rotate(180deg);
  }
}

@media screen and (max-width: 768px) {
  ul {
    flex-direction: column;
    display: none;
  }
  #menubutton {
    /*makes the button visible*/
    display: block;

    /*since we are here, we can style it a little bit!*/
    /* padding: 2vw;
    font-size: 3vh;
    background-color: #AFE0FF;
    border: none; */

    /*outline none removes the default blue border that appears anytime you click on the button*/
    outline: none;

    cursor: pointer;
    align-self: flex-start;
  }
}
</style>
