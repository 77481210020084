<template>
  <div class="programme">
    <div class="channel">
      <img :src="channel.images.ipad_live_122x62.url" :alt="channel.title" />
    </div>
    <div class="info" @click="show = !show">
      <div class="time">{{formDate(program.catchupEventStartDate)}}</div>
      <div class="title">{{program.title}}</div>
      <!-- <div>{{program.genres}}</div> -->
      <!-- <div>{{program.length}}</div> -->
      <div v-show="show" class="description">{{program.longDescription}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Programme",
  props: ["channel", "title", "start", "program"],
  data() {
    return {
      show: false
    };
  },
  methods: {
    formDate: function(date) {
      let inDate = new Date(date);
      return inDate.toLocaleTimeString("sv-SE");
    }
  }
};
</script>

<style scoped>
.programme {
  display: grid;
  grid-template-columns: 122px 1fr;
  grid-column-gap: 5px;
}
.title {
  font-size: 1.2em;
  font-weight: bold;
}
.time {
  font-weight: bold;
}
/* .info {
  display: grid;
  grid-template-rows: 1fr 1fr;
} */
</style>